export const static_translation = {
    "dexsire_is_respect": "Dexsire is respect,",        
    "safety_and_dignity": "safety and dignity",        
    "register_right_now": "Register right now.",        
    "register": "Register",        
    "our_escorts": "Our escorts",        
    "discover_some_models_available_to_you": "Discover some models available to you",        
    "load_more": "Load more",        
    "connecting_you_to_the_highest_level_of_experience": "Connecting you to the highest level of experience.",        
    "find_escorts_by_region": "Find escorts by region",        
    "contact_us": "Contact us",        
    "frequently_asked_questions": "Frequently asked questions",        
    "copyright_2024_dexsire": "© Copyright 2024 Dexsire, Switzerland. All rights reserved.",        
    "privacy_policy": "Privacy Policy",        
    "terms_of_service": "Terms of Service",        
    "and": "and",        
    "service_terms": "Service Terms",    
    "canton":"Canton",
    "ville":"Ville",
    "services_offered":"Services offered",
    "category":"Category",
    "clear_filter":"Clear filter",
    "find_escorts":"Find escorts",
    "find_the_ideal_model":"Find the ideal model for you",
    "login_register": "Login/Register",    
    "announcement": "Announcement",    
    "my_profile": "My profile",    
    "exit": "Exit",    
    "home": "Home",    
    "advanced_search": "Advanced Search",    
    "advertise_here": "Advertise here",    
    "contact": "Contact",    
    "im_a_escorts": "I'm a escorts",
    "im_a_customer": "I'm a customer",
    "i_already_have_registration": "I already have registration",
    "i_want_to_sign_up": "I want to sign up",
    "login": "Login",
    "enter_your_username_and_password": "Enter your username and password.",
    "i_forgot_my_password": "I forgot my password",
    "to_enter": "To enter",
    "email": "E-mail",
    "password": "Password",
    "fill_email_field": "Fill email field",
    "fill_name_field": "Fill name field",
    "fill_password_field": "Fill password field",
    "fill_360_video": "Fill 360 video",
    "minimum_4_photos": "Minimum 4 photos",
    "fill_pseudo_field": "Fill Pseudo field",
    "fill_category_field": "Fill Category field",
    "fill_bio_field": "Fill Bio field",
    "fill_age_field": "Fill Age field",
    "your_age_must_be_over_18": "Your age must be over 18",
    "fill_phone_field": "Fill Phone field",
    "fill_canton_field": "Fill Canton field",
    "fill_ville_field": "Fill Ville field",
    "fill_nationality_field": "Fill Nationality field",
    "fill_size_field": "Fill Size field",
    "fill_weight_field": "Fill Weight field",
    "fill_hair_color_field": "Fill Hair color field",
    "fill_breasts_field": "Fill Breasts field",
    "fill_eyes_color_field": "Fill Eyes color field",
    "select_at_least_one_mobility_option": "Select at least one mobility option",
    "select_at_least_one_payment_option": "Select at least one payment option",
    "select_at_least_one_language_option": "Select at least one language option",
    "fill_in_your_details": "Fill in your details",
    "user_registration": "User registration",
    "plan": "Plan",
    "select_a_plan": "Select a plan",
    "personal_data": "Personal data",
    "profile": "Profile",
    "appearance": "Appearance",
    "payment": "Payment",
    "model_registration": "Model registration",
    "buy_highlights": "Buy highlights",
    "fill_in_your_profile": "Fill in your profile",
    "name":"Name", 
    "confirm_password":"Confirm password",
    "fill_all_fields":"Fill all fields",
    "invalid_email":"Invalid email",
    "password_and_confirmation_not_match":"Password and confirmation not match",
    "account_successfully_registred":"Account successfully registred!",
    "registration_completed_successfully":"Registration completed successfully",
    "you_must_verify":"You must verify your email to activate your account. If you don't find it in your inbox, please check your spam folder.",
    "take_the_opportunity":"Take the opportunity to highlight your profile, invest in credits to stay on top.",
    "want_to_buy_later":"Want to buy later",
    "i_want_to_buy_credits_now":"I want to buy credits now",
    "advance":"Advance",
    "now_its_time_to_report_your_appearance":"Now it's time to report your appearance",
    "verification_photo":"Verification Photo",
    "exemple":"Exemple",
    "send_a_photo_holding":"send a photo holding a paper with dexsire.com written on it",
    "drag_the_image_here_or_click_here":"Drag the image here or click here",
    "i_agree_with_the_platform":"I agree with the platform's",
    "accept_terms_to_continue":"Accept terms to continue",
    "pseudo": "Pseudo",
    "bio": "Bio",
    "age": "Age",
    "phone": "Phone",
    "nationality": "Nationality",
    "size": "Size",
    "weight": "Weight",
    "height": "Height",
    "language": "Language",
    "hair_color": "Hair color",
    "breasts": "Breasts",
    "eye_color": "Eye color",
    "mobility": "Mobility",
    "payments_accepted": "Payments accepted",
    "languages_spoken": "Languages spoken",
    "what_services_do_you_offer": "What services do you offer?",
    "describe_yourself_below": "Describe yourself below:",
    "note": "Note:",
    "the_file_is_too_long": "The file is too large. The maximum size is 50MB.",
    "upload_a_360": "Upload a 360º video",
    "instruction_on_how": "Instructions on how to record 360º video",
    "drag_video": "Drag the video here or click here",
    "supported_video": "Supported files: MP4 or AVI",
    "maximum_video": "Maximum file size: 50mb",
    "upload": "Upload",
    "drag_image": "Drag the image here or click here",
    "supported_image": "Supported files: JPG and PNG",
    "maximum_image": "Maximum file size: 8mb. Minimum 4 photos.",
    "the_image_is_too_big": "The file is too large. The maximum size is 8MB.",
    "in_addition": "In addition to the periodic highlight included as part of the control panel functionalities, you will also have the option to purchase additional highlights. This will allow you to feature your ads more frequently or for longer periods of time, further increasing your exposure on the platform.",
    "license": "License",
    "license_5days": "5 days - License",
    "license_5days_price": "89.90 francs",
    "payment_via": "Payment for your subscription will be made via",
    "continue": "Continue",
    "choose_plan": "Choose plan",
    "announcement_title_1": "Clean and intuitive interface",
    "announcement_desc_1": "We offer a clean and intuitive interface so models can manage their ads efficiently and without complications. Our platform is designed to be easy to use, providing a stress-free and enjoyable ad management experience.",
    "announcement_title_2": "Ad management options",
    "announcement_desc_2": "Have full control over your ads with our create, edit and delete options. You can create new ads, edit existing information, and remove ads as needed, all in one place.",
    "announcement_title_3": "Updating personal information",
    "announcement_desc_3": "Keep your information up to date with ease. With our platform, you can update personal information like photos and description, ensuring your customers receive the latest information about your services.",
    "announcement_title_4": "Highlight every 45 Minutes",
    "announcement_desc_4": "Stand out every 45 minutes to maximize your visibility and attract more customers. With just one click, you can ensure your ad is displayed prominently, standing out among the rest for greater exposure.",
    "announcement_title_5": "Buy highlights",
    "announcement_desc_5": "Increase your visibility and reach by purchasing additional highlights for your ads. We offer flexible options so you can choose the plan that best suits your needs and budget, ensuring your profile gets the attention it deserves.",
    "announcement_title_ads": "Advertise here",
    "announcement_desc_ads_1": "Join us and enjoy a platform dedicated to promoting your security, visibility and success.",
    "announcement_desc_ads_2": "When you register with LUXEO, you will have access to a series of exclusive benefits, including:",
    "announcement_desc_ads_3": "Do not waste time! Register now and embark on this successful journey with us at LUXEO.",
    "announcement_action_ads": "Register now",
    "announcement_section_title1": "Custom Profile",
    "announcement_section_desc1": "Create a unique and attractive profile that highlights your talents, services and personality.",
    "announcement_section_title2": "Expand your visibility",
    "announcement_section_desc2": "Reach a wider audience and increase your business opportunities by being featured in our 'Featured Girls' section.",
    "announcement_section_title3": "Ease of Use",
    "announcement_section_desc3": "Our intuitive and user-friendly platform makes managing your profile and interacting with potential clients simple.",
    "announcement_section_title4": "Positive Reviews",
    "announcement_section_desc4": "Receive valuable feedback from satisfied customers and increase your reputation in the community.",
    "announcement_section_title5": "Special Promotions",
    "announcement_section_desc5": "Get exclusive access to special offers and promotional events to boost your business.",    
    "announcement_form_title": "Login",
    "announcement_form_subtitle": "I'm already an advertiser",    
    "announcement_form_action1": "Register",
    "announcement_form_action_title": "I have no contact on the platform",
    "announcement_form_action2": "Create an account",    
    "announcement_form_email": "E-mail",
    "announcement_form_password": "Password",
    "announcement_form_forgot": "I forgot my password",
    "announcement_form_submit": "Login",    
    "forgot_title": "I forgot my password",
    "forgot_subtitle": "Enter your registration email",
    "forgot_input_email": "Email",
    "forgot_action": "Send link for password renewal",    
    "forgot_success_title": "Email successfully sent.",
    "forgot_success_text": "We have sent you an email with a link to renew your password. If you can't find it, check your spam folder.",    
    "create_password_validate1": "Fill new password field",
    "create_password_validate2": "Fill new password confirmation field",
    "create_password_validate3": "Password and password confirmation not match",
    "create_password_success": "Password created successfully",
    "create_password_title": "I forgot my password",
    "create_password_text": "Create a new password",
    "create_password_input_1": "New password",
    "create_password_input_2": "New password confirmation",
    "create_password_input_submit": "Save",    
    "purchase_title": "Highlight your profile",
    "purchase_subtitle": "Purchase of credits",
    "purchase_text": "Select how many credits you want to buy",
    "purchase_action": "I want to buy later",    
    "purchase_selected": "Selected",
    "purchase_select": "Select",    
    "customer_profile_name": "Name",
    "customer_profile_user": "User since",
    "customer_profile_status": "Status",
    "customer_profile_email": "E-mail",
    "customer_profile_subs_type": "Subscription type",
    "customer_profile_subs_value": "Subscription value",
    "customer_profile_saved": "Saved",
    "customer_profile_save": "Save",
    "customer_profile_edit": "Edit information",
    "customer_profile_delete": "Delete account",
    "customer_profile_fav": "Escorts you added to favorites",    
    "gallery_video": "Video Gallery",
    "gallery_image": "Photo Gallery",
    "gallery_load_more": "Load more",    
    "aboutme_contact": "Contact me",
    "aboutme_contact_text": "I don't answer voice calls",
    "aboutme_contact_some_photos": "See some photos and videos",
    "aboutme_contact_photos": "Photos",
    "aboutme_contact_videos": "Videos",    
    "review_service": "Service",
    "review_hygiene": "Hygiene",
    "review_socialtime": "Social time",
    "review_apparence": "Appearance",
    "review_location": "Location",
    "review_comment": "Comment",
    "review_fill_all_fields": "Fill all fields",
    "review_what_customer_saying": "What some customers are saying:",
    "review_left_comment": "Left your comment",
    "review_add_your_review": "Add your review",
    "review_sent_review": "Sent review",    
    "escortservice_success": "Saved successfully",
    "escortservice_title": "Contact me",
    "escortservice_text": "I don't answer voice calls",    
    "contact_title": "Contact",
    "contact_email": "E-mail",
    "contact_whats_desc": "Talk to us via WhatsApp.",
    "contact_whats_label": "Clique here",
    "contact_privacy_title": "Privacy policy",
    "contact_privacy_desc": "Receive valuable feedback from satisfied customers and increase your reputation in the community.",
    "contact_privacy_label": "Access FAQ",    
    "contactform_name": "Your name",
    "contactform_email": "Your best email",
    "contactform_subject": "Subject",
    "contactform_message": "Message",
    "contactform_fill_fields": "Fill all fields",
    "contactform_invalidmail": "Invalid email",
    "contactform_success": "Contact sented successfully",
    "contactform_title": "Need help? It will be a pleasure to talk to you.",
    "contactform_action": "Send message",    
    "faq_title": "Frequently asked questions",
    "faq_subtitle": "Frequently asked questions from users about using the application, its features and policies.",
    "faq_text": "Did not find what you were looking for?",
    "faq_subtext": "Contact us.",
    "faq_action": "Send Message",    
    "notfound_title": "Error 404",
    "notfound_text": "The page you tried to access does not exist or is temporarily down.",
    "notfound_action": "Return to home",    
    "advancedsearch_canton": "Canton",
    "advancedsearch_age": "Age",
    "advancedsearch_services": "Services offered",
    "advancedsearch_category": "Category",
    "advancedsearch_customerreviews": "Customer reviews",
    "advancedsearch_nationality": "Nationality/Ethnic Origin",
    "advancedsearch_lang": "Spoken languages",
    "advancedsearch_appearance": "Physical appearance",
    "advancedsearch_find": "Find escorts",
    "advancedsearch_title": "Advanced search",
    "terms_title1": "1. Introduction",
    "terms_content1": "The Terms of Service page is an essential part of our platform, defining the terms and conditions that govern users use of our services. This page establishes the rights and responsibilities of both users and the company, ensuring a safe and transparent experience for everyone involved.",    
    "terms_title2": "2. Acceptance of Terms",
    "terms_content2": "We explain that the use of our platform implies automatic and binding acceptance of the Terms of Service.",
    "terms_title3": "3. Description of Services",
    "terms_content3": "We detail the services offered by our platform, including their functionalities, characteristics, and limitations. This gives users a clear understanding of what they can expect when using our services.",    
    "terms_title4": "4. User Responsibilities",
    "terms_content4": "We clarify users responsibilities when using our platform, including: providing accurate and up-to-date information during registration, respecting intellectual property rights, maintaining the security of access credentials, and not violating applicable laws or the rights of third parties.",    
    "terms_title5": "5. Intellectual Property",
    "terms_content5": "We inform users about the ownership of copyrights, trademarks, and other intellectual property rights related to our platform and its content. Users agree to respect these rights and not use our content in an unauthorized manner.",    
    "terms_title6": "6. Privacy Policy",
    "terms_content6": "We reference our privacy policy and explain how we treat user information. Users are encouraged to review our privacy policy to understand how their information is collected, used, and protected.",    
    "terms_title7": "7. Modifications to the Terms of Service",
    "terms_content7": "We reserve the right to modify or update the Terms of Service as necessary. Changes become effective upon publication of the revised Terms on our platform.",    
    "terms_title8": "8. Limitation of Liability",
    "terms_content8": "We exempt the company from liability for direct, indirect, incidental, consequential, or punitive damages resulting from the use of our services. Users agree to use our services at their own risk.",    
    "terms_title9": "9. Termination of Service",
    "terms_content9": "We reserve the right to terminate or suspend users access to our platform, without prior notice, in case of violation of the Terms of Service.",    
    "terms_title10": "10. General Provisions",
    "terms_content10": "We include provisions on applicable law, jurisdiction, waiver of rights, contract integrity, and other general provisions to ensure the enforceability and effectiveness of the Terms of Service.",    
    "terms_title11": "11. Contact",
    "terms_content11": "We provide contact information so that users can contact us with any questions, concerns, or issues regarding the Terms of Service.",    
    "terms_title": "Terms of service",
    "terms_subtitle": "Carefully read the platform's terms of service before proceeding.",    
    "purchasedetails_title": "Purchase details",
    "purchasedetails_text1": "You will have your ad",
    "purchasedetails_text2": "After this period, you must reactivate your subscription.",
    "purchasedetails_text3": "You can pause your ad at any time before the deadline ends. This way the credits will also be paused for you to use whenever you want.",
    "purchasedetails_text4": "Plan value",
    "purchasedetails_text_francs": "francs",
    "purchasedetails_text_confirm": "Confirm payment",
    "purchasedetails_text_congratulations1": "Congratulations. Your",
    "purchasedetails_text_congratulations2": "featured plan has been successfully activated!",    
    "profilemodal_option1": "Customer Profile",
    "profilemodal_option2": "Internal page",
    "profilemodal_option3": "Administrative owner",
    "profilemodal_option4": "Administrative escort",
    "profilemodal_title": "Choose the profile type",
    "privacy_title1": "1. Introduction",
    "privacy_conte1": "Our privacy page aims to inform our users about how we collect, use and protect their personal information while they use our platform. The privacy of our users is a priority for us, and this page details our privacy practices in compliance with applicable laws and regulations.",
    "privacy_title2": "2. Information Collected",
    "privacy_conte2": "In this section, we describe the types of personal information we collect from users and the methods by which this information is obtained. This includes, but is not limited to: Information provided by users during registration, such as name, email, date of birth, etc. Usage data such as browsing history, platform interactions, user preferences, etc. Information collected automatically through cookies and similar technologies.",
    "privacy_title3": "3. Use of Information",
    "privacy_conte3": "We explain how users personal information is used by our platform. This may include: Personalizing the user experience and delivering relevant content. Continuous improvement of our services and features. Communicating with users about updates, special offers, etc. Compliance with legal and regulatory obligations.",
    "privacy_title4": "4. Information Sharing",
    "privacy_conte4": "We detail who we share user information with and the reasons for such sharing. This may involve: Service partners who assist us in providing our services. Legal authorities, when required by law or in response to official requests. Third parties in the event of a merger, acquisition or sale of assets.",
    "privacy_title5": "5. Information Protection",
    "privacy_conte5": "We explain the security measures we implement to protect users information against unauthorized access, misuse, alteration or disclosure. This may include: Data encryption. Access controls. Regular monitoring of systems and networks.",
    "privacy_title6": "6. User Rights",
    "privacy_conte6": "We inform users of their rights in relation to their personal information, including the right to access, correct, delete or limit the use of their data. We also provide information on how users can exercise these rights.",
    "privacy_title7": "7. Changes to the Privacy Policy",
    "privacy_conte7": "We reserve the right to update or modify our privacy policy as necessary. Any significant changes will be communicated to users through our platform or by other appropriate means.",
    "privacy_title8": "8. Contact",
    "privacy_conte8": "We provide contact information so that users can contact us if they have any privacy-related questions, concerns, or requests.",
    "privacy_title9": "9. Effective Date",
    "privacy_conte9": "We indicate the effective date of our privacy policy and inform users of the importance of regularly reviewing this page to stay up to date with our privacy practices.",
    "privacy_title10": "10. Legal Compliance",
    "privacy_conte10": "We declare our commitment to compliance with applicable data protection laws and regulations and inform users of their rights and remedies in the event of privacy violations.",    
    "privacy_title": "Privacy Policy",
    "privacy_subtitle": "Carefully read the platform's terms of use before proceeding.",    
    "deleteaccount_success": "Account deleted successfully",
    "deleteaccount_title": "You are about to permanently delete your account.",
    "deleteaccount_text": "If you have decided not to delete your account at this time, you can simply close this page and continue using our platform as usual. If you have questions or need assistance, don't hesitate to contact our support team. We are here to help!",
    "deleteaccount_confirm": "Confirm account deletion",
    "deleteaccount_cancel": "I don't want to delete",
    "deleteaccount_success_title": "Your account has been successfully deleted.",
    "deleteaccount_success_text": "It was a pleasure having you spend this time with us. Remember that you are welcome whenever you need us. We will be here to help you.",    
    "ageverification_title": "Age verification",
    "ageverification_text1": "This website contains content restricted to people over 18 years of age. By clicking 'Confirm', you are certifying that you are of legal age under the laws of your jurisdiction. If you are not of the required minimum age, please leave this site immediately.",
    "ageverification_text2": "False age confirmation is a violation of this site's terms of use.",
    "ageverification_text3": "Are you over 18",
    "ageverification_text4": "years of age?",
    "ageverification_yes": "Yes",
    "ageverification_no": "No",
    "ageverification_policy": "Privacy Policy",
    "ageverification_terms": "Terms of use",    
    "escortinfo_reviews": "Reviews left by customers",    
    "follower": "Follower",
    "unfollow": "Follow",    
    "imagesuccessfully": "Image uploaded successfully",    
    "admin_side_option1": "Home",
    "admin_side_option2": "About me",
    "admin_side_option3": "Photos",
    "admin_side_option4": "Videos",
    "admin_side_option5": "Ratings",
    "admin_side_option6": "Services",
    "admin_side_option7": "Adverts",
    "admin_side_option8": "Additional credits",
    "admin_side_option9": "Plans",
    "admin_side_option10": "Statistics",
    "admin_side_option11": "Support",    
    "admin_side_option12": "Home",
    "admin_side_option13": "Users",
    "admin_side_option14": "Escorts",
    "admin_side_option15": "Reports",    
    "admin_sidefooter_option1": "Delete account",
    "admin_sidefooter_option2": "Exit",    
    "admin_dashboard_title1": "Favorites",
    "admin_dashboard_subtitle1": "Users who are following you",
    "admin_dashboard_title2": "Ad clicks",
    "admin_dashboard_subtitle2": "Last 30 days",
    "admin_dashboard_title3": "Your credits",
    "admin_dashboard_subtitle3": "The credits are over",
    "admin_dashboard_title4": "Subscription status",
    "admin_dashboard_subtitle4": "Almost expiring",    
    "admin_dashboard_credit": "credit",
    "admin_dashboard_announcement": "credAnnouncementit",    
    "admin_dashboard_visit": "Visits to your profile",
    "admin_dashboard_visits": "visits",    
    "admin_dashboard_activead": "active ad",    
    "admin_dashboard_aboutme_success": "About me updated successfully",
    "admin_dashboard_aboutme_title": "About me",
    "admin_dashboard_aboutme_phone": "Phone",
    "admin_dashboard_aboutme_whats": "Whatsapp",
    "admin_dashboard_aboutme_telegram": "Telegram",
    "admin_dashboard_aboutme_size": "Size",
    "admin_dashboard_aboutme_weight": "Weight",
    "admin_dashboard_aboutme_nationality": "Nationality",
    "admin_dashboard_aboutme_haircolor": "Hair color",
    "admin_dashboard_aboutme_breasts": "Breasts",
    "admin_dashboard_aboutme_eyecolor": "Eye color",
    "admin_dashboard_aboutme_gender": "Preference (Gender)", 
    "admin_dashboard_credits_credit": "credit",
    "admin_dashboard_credits_franc": "francs",    
    "admin_dashboard_credits_item1_title": "How does the subscription work?",
    "admin_dashboard_credits_item1_subtitle": "You can subscribe for a specific time to be featured on the platform.",
    "admin_dashboard_credits_item1_button": "30 days",
    "admin_dashboard_credits_item1_footer": "After this period, you need to renew your subscription so that your ads are highlighted on the platform and increase the chances of being hired by users.",    
    "admin_dashboard_credits_item2_title": "Credits and periods",
    "admin_dashboard_credits_item2_subtitle": "For each day of featured ad, you will need 1 credit.",
    "admin_dashboard_credits_item2_footer": "For each day of featured ad, you will need 1 credit.",    
    "admin_dashboard_credits_item3_title": "Purchase of credits",
    "admin_dashboard_credits_item3_subtitle": "Select how many credits you want to buy.",    
    "admin_dashboard_credits_title": "Your credits",
    "admin_dashboard_credits_subtitle": "Your credits have expired. You need to buy more credits to be featured on the platform.",    
    "admin_dashboard_plans1_title": "Basic Plan",
    "admin_dashboard_plans1_subtitle": "Featured for 5 days",
    "admin_dashboard_plans1_text": "You can have your ad featured for 5 days.",
    "admin_dashboard_plans1_label": "Choose plan",    
    "admin_dashboard_plans2_title": "Bronze Plan",
    "admin_dashboard_plans2_subtitle": "Featured for 15 days",
    "admin_dashboard_plans2_text": "You can have your ad featured for 2 weeks.",
    "admin_dashboard_plans2_label": "Choose plan",    
    "admin_dashboard_plans3_title": "Premium Plan",
    "admin_dashboard_plans3_subtitle": "Featured for 30 days",
    "admin_dashboard_plans3_text": "You can have your ad featured for 1 month.",
    "admin_dashboard_plans3_label": "Choose plan",    
    "admin_dashboard_plans_title": "Your credits",
    "admin_dashboard_plans_subtitle": "Your credits have expired. You need to buy more credits to be featured on the platform.",    
    "admin_dashboard_plans_choose": "Choose plan",
    "admin_dashboard_plans_buymore": "Buy more credits",
    "admin_dashboard_stats_12": "12 months",
    "admin_dashboard_stats_6": "6 months",
    "admin_dashboard_stats_30": "30 days",
    "admin_dashboard_stats_7": "7 days",    
    "admin_dashboard_opt1_title": "Added to favorites",
    "admin_dashboard_opt1_text": "additions",    
    "admin_dashboard_opt2_title": "Clicks on the Whatsapp button",
    "admin_dashboard_opt2_text": "clicks",    
    "admin_dashboard_sup_subject": "Subject",
    "admin_dashboard_sup_message": "Message",
    "admin_dashboard_sup_fill": "Fill all fields",
    "admin_dashboard_sup_success": "Support sented successfully",
    "admin_dashboard_sup_title": "If you have any questions, our support team will be happy to answer.",
    "admin_dashboard_sup_action": "Send Message",    
    "admin_dashboard_supfooter_email": "E-mail",
    "admin_dashboard_supfooter_phone": "Telephone",    
    "admin_dashboard_supfooter_item1_title": "Frequently asked questions",
    "admin_dashboard_supfooter_item1_subtitle": "We have separated some questions that are requested here.",
    "admin_dashboard_supfooter_item1_label": "Access FAQ",    
    "admin_dashboard_supfooter_item2_title": "Privacy policy",
    "admin_dashboard_supfooter_item2_subtitle": "Understand a little more about privacy policies.",
    "admin_dashboard_supfooter_item2_label": "Access",    
    "admin_dashboard_supfooter_item3_title": "Service Terms",
    "admin_dashboard_supfooter_item3_subtitle": "Understand a little more about the terms of service.",
    "admin_dashboard_supfooter_item3_label": "Access",    
    "admin_dashboard_delete_text": "If you have decided not to continue using our platform, we offer the option to permanently delete your account. This process is irreversible and will result in the complete removal of all information associated with your account, including your profile, activity history and personal data. Please be aware that when you delete your account, you will lose access to all features and services offered by the platform. Additionally, any previously completed transactions, messages, or interactions will be permanently deleted and cannot be recovered. If you are sure you want to proceed with deleting your account, please confirm your decision below. Once confirmed, your account will be permanently deleted and you will be automatically logged out of the platform",
    "admin_dashboard_delete_title": "What do you need to know before deleting your account?",
    "admin_dashboard_delete_action": "Delete my account",    
    "what_are_you_looking_for": "What are you looking for?",    
    "admin_dashboardowner_item1_title": "New users",
    "admin_dashboardowner_item1_subtitle": "Last 30 days",
    "admin_dashboardowner_item1_legend": "new users",    
    "admin_dashboardowner_item2_title": "New escort",
    "admin_dashboardowner_item2_subtitle": "Last 30 days",
    "admin_dashboardowner_item2_legend": "new models",
    "admin_dashboardowner_item3_title": "Visits to the website",
    "admin_dashboardowner_item3_subtitle": "Last 30 days",
    "admin_dashboardowner_item4_title": "Payment methods",
    "admin_dashboardowner_item4_subtitle": "Method used by your customers",
    "admin_dashboardowner_item4_value": "stripe",
    "admin_dashboardowner_escortcontrol_title": "Escorts to approve",
    "admin_dashboardowner_escortcontrol_subtitle": "Make sure the model meets all platform policies.",
    "admin_dashboardowner_escortcontrol_seeall": "See all requests",    
    "admin_dashboardowner_escortcontrol_approve": "To approve",
    "admin_dashboardowner_escortcontrol_reject": "Reject",
    "admin_dashboardowner_escortcontrol_seemore": "See more",    
    "admin_dashboardowner_top_title": "Top escorts",
    "admin_dashboardowner_top_subtitle": "Last 30 days",
    "admin_dashboardowner_top_complete": "Complete ranking",    
    "admin_dashboardowner_older": "Older",
    "admin_dashboardowner_newest": "Newest",    
    "admin_dashboardowner_user": "Active users",
    "admin_dashboardowner_deleted": "Deleted your accounts",
    "admin_dashboardowner_platformuser": "Platform users",
    "admin_dashboardowner_usersince": "User since",
    "admin_dashboardowner_lastaccess": "Last access",    
    "admin_dashboardowner_selectperiod": "Select the period",
    "admin_dashboardowner_sortby": "Sort by",
    "admin_dashboardowner_newuser": "new users",
    "admin_dashboardowner_newescorts": "new escorts",
    "admin_dashboardowner_newmodels": "new models",
    "admin_dashboardowner_modelstitle": "Models",
    "admin_dashboardowner_userstitle": "Users",
    "admin_dashboardowner_modelranking": "Model ranking",
    "admin_dashboardowner_busiestaccess": "Busiest access times on the platform",
    "admin_dashboardowner_hits": "hits",
    "admin_dashboardowner_accessesperiod": "Accesses by period",    
    "admin_dashboardowner_models": "models",
    "admin_dashboardowner_activeuser": "active users",
    "admin_dashboardowner_deletedaccount": "Deleted your accounts",    
    "admin_dashboardowner_user_name": "Name",    
    "admin_dashboardowner_user_email": "E-mail",
    "admin_dashboardowner_user_phone": "Telephone",
    "admin_dashboardowner_user_whatsapp": "Whatsapp",
    "admin_dashboardowner_user_telegram": "Telegram",
    "admin_dashboardowner_user_usersince": "User since",
    "admin_dashboardowner_user_status": "Status",
    "admin_dashboardowner_user_active": "Active",
    "admin_dashboardowner_user_inactive": "Inactive",
    "admin_dashboardowner_user_lastaccess": "Last access",
    "admin_dashboardowner_user_modify": "Modify information",
    "admin_dashboardowner_user_save": "Save",
    "admin_dashboardowner_user_redefine": "Redefine password",
    "admin_dashboardowner_user_suspend": "Suspend account",
    "admin_dashboardowner_user_sendemail": "Send email",
    "admin_dashboardowner_user_success": "Updated Successfully",
    "admin_dashboardowner_user_successsuspend": "Suspended Successfully",
    "admin_dashboardowner_user_successreset": "Reset password email sent",
    "admin_dashboardowner_user_back": "Back",
    "admin_dashboardowner_user_actions": "Admin actions",
    "admin_dashboardowner_escorts_it1_title": "Active models",
    "admin_dashboardowner_escorts_it2_title": "Deleted your accounts",
    "admin_dashboardowner_escorts_it3_title": "Escorts banned",    
    "admin_dashboardowner_escorts_col1_title": "Platform users",
    "admin_dashboardowner_escorts_col2_title": "User since",
    "admin_dashboardowner_escorts_col3_title": "Last access",
    "admin_dashboardowner_escorts_col4_title": "Account deletion date",
    "admin_dashboardowner_escorts_col5_title": "Ban date",
    "admin_dashboardowner_loadmore": "Load more",    
    "availability_hours": "Availability hours",
    "price": "Price",
    "where_do_you_serve": "Where do you serve",
    "availability": "Availability",
    "duration": "Duration",
    "value": "Value",
    "location": "Location",
    "about_me": "About me",
    "service": "Service",
    "hygiene": "Hygiene",
    "socialtime": "Social Time",
    "nodata": "Date not available",
    "nocomment": "No comments available.",
    "annon": "Anonymous",
    "noreview": "No reviews available.",    
    "addverification": "Add verification badge",
    "removeerification": "Remove verification badge",    
    "admin_dashboardowner_morning": "Morning",
    "admin_dashboardowner_afternoon": "Afternoon",
    "admin_dashboardowner_night": "Night",
    "admin_dashboardowner_early_morning": "Early morning",    
    "admin_dashboardowner_activeescorts": "Active escorts",
    "admin_dashboardowner_activeusers": "Active users",
    "admin_dashboardowner_bannedescorts": "Banned escorts",
    "admin_dashboardowner_bannedusers": "Banned users",    
    "admin_dashboardowner_visitprofile": "Visit profile",
    "city_state": "City State",
    "posts": "posts",
    "videos": "videos",
    "likes": "likes",
    "comments": "comments",
}